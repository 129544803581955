
.embla {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.embla__slide {
  min-width: 100%;
  position: relative;
  margin: 0px;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
}

.embla__slide__img {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.embla--thumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.embla__container--thumb {
  cursor: default;
}

.embla__slide--thumb {
  min-width: 20%;
  border: 1px solid transparent;
  margin-right: 5px;

}

.embla__slide--thumb:hover {
  border: 1px solid #cc4a33;
}

.embla__slide__inner--thumb {
  touch-action: manipulation;
  cursor: pointer;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  height: 100px;
  width: 100%;
  background-color: transparent;
  position: relative;
  display: block;
  overflow: hidden;
}

.embla__slide__thumbnail {
  position: absolute;
  opacity: 0.2;
  top: 0;
  bottom: 0;
  left: -10000%;
  right: -10000%;
  margin: auto;
  min-width: 1000%;
  min-height: 1000%;
  max-width: 100%;
  transform: scale(0.1);
  transition: opacity 0.2s;
  object-fit: none;
}

.embla__slide--thumb.is-selected .embla__slide__thumbnail {
  opacity: 1;
}
