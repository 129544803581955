ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}

.MuiDialog-paperWidthSm {
  min-width: 50%;
}

.MuiDialog-paperWidthSm {
  max-width: 100%;
}

.MuiAlert-standardError {
  background-color: #ff5252 !important;
}

.snackbarSuccess {
  background-color: #cc4a33 !important;
}

.snackbarInfo {
  background-color: #333333 !important;
}

.snackbarError {
  background-color: #ff5252 !important;
  color: #FFF;
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  margin: 0;
  background: #F2F2F2;
}


.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #cc4a33;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
    line-height: 30px;
  margin: 10px;
    padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #cc4a33;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

